import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/jon/projects/shanti-aid-gatsby/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Shanti Aid supported West Midlands Police`}</h1>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d7de223be052d080f18212fd2d6a05ac/54af7/operation-limelight.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABmy6wkKln/8QAGRABAQADAQAAAAAAAAAAAAAAAgEAAxIT/9oACAEBAAEFAu5IWE5Bc264QTy/STP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECERIxIUFh/9oACAEBAAY/Al6Y07OyC2rHLDZXJ//EABsQAQACAgMAAAAAAAAAAAAAAAEAIRFBUXGB/9oACAEBAAE/IV2mSCYZaILkitVDhlNOjFth5P/aAAwDAQACAAMAAAAQoP8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/ELGP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EJDX/8QAGxABAQADAQEBAAAAAAAAAAAAAREAIUExcWH/2gAIAQEAAT8QOO+nIeX7c0RwE2vvfzApgdR4mnBiQG7h7X24WyENNkJ37j4Q8jP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Operation Limelight",
            "title": "Operation Limelight",
            "src": "/static/d7de223be052d080f18212fd2d6a05ac/4b190/operation-limelight.jpg",
            "srcSet": ["/static/d7de223be052d080f18212fd2d6a05ac/e07e9/operation-limelight.jpg 200w", "/static/d7de223be052d080f18212fd2d6a05ac/066f9/operation-limelight.jpg 400w", "/static/d7de223be052d080f18212fd2d6a05ac/4b190/operation-limelight.jpg 800w", "/static/d7de223be052d080f18212fd2d6a05ac/e5166/operation-limelight.jpg 1200w", "/static/d7de223be052d080f18212fd2d6a05ac/b17f8/operation-limelight.jpg 1600w", "/static/d7de223be052d080f18212fd2d6a05ac/54af7/operation-limelight.jpg 2016w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <hr></hr>
    <p>{`Shanti Aid supported West Midlands Police with Operation Limelight to make the public aware of FGM and Forced Marriages.`}</p>
    <p>{`The event took place at Birmingham Airport.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      